<template>
  <div class="digital-twin-page">
    <Nav />
    <PageHero :heroSrc="heroSrc" :title="title" :sub="sub" />
    <Charts />
    <SubPageContent :sections="sections" pageName="dt" />

    <!-- <Menu /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import PageHero from "@/components/PageHero.vue";
import SubPageContent from "@/components/SubPageContent.vue";
import Menu from "@/components/Menu.vue";
import Charts from "@/components/digital-twin/Charts.vue";

export default {
  name: "DigitalTwin",
  components: { PageHero, Menu, Charts, SubPageContent, Nav },
  data: () => {
    return {
      heroSrc: "img/dw-bk.webp",
      title: "DIGITAL TWIN:",
      sub:
        "IF A PICTURE IS WORTH A 1000 WORDS, THAN THE WORTH OF A 3D MODEL IS...",
      sections: [
        {
          p:
            "Creating a 3D digital twin changes the entire approach of how we observe, measure, learn, calculate and develop our product.",
        },
        {
          p:
            "It doesn't matter if your product or space is a merchant ship or a microchip, with a digital twin you can easily dive into a digital universe that enables an absolute control with endless utilities.",
        },
        {
          p:
            "CONTROL YOUR DIGITAL TWIN: in3D develops 3D software/applications or 3D websites to manage and control your digital twin. 3D models utilities' potential is never-ending: measuring, styling, instructing, engineering, planning, tracking, marketing and the list goes on, but in order to fulfill and control that potential you need a high quality 3D platform that will enable you to take your business operations to the next level.",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
</style>